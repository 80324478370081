<template>
  <div>
    <clientIndex></clientIndex>
  </div>
</template>

<script>
import { Session } from '../../plugins/session'
import store from '@/store'

const getPageData = (routeTo, next) => {
  store
    .dispatch('storyStore/readById', Session.story._id)
    .then(() => {
      store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { backgroundColor: Session.story.color })
      next()
    })
    .catch(() => {})
}
export default {
  components: {
    clientIndex: () => import('@/views/components/pageAPI/clientIndex.vue' /* webpackChunkName: "clientIndex" */)
  },
  beforeRouteEnter(to, from, next) {
    getPageData(to, next)
  },
  beforeRouterUpdate(to, from, next) {
    getPageData(to, next)
  }
}
</script>

<style>
</style>

